.sorting-switch {
    justify-content: center;
    margin: 8px 0
}

.sorting-button {
    width: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.sorting-button.active {
    background-color: var(--red);
    color: #fff;
    border-color: var(--red);
}

.sorting-button:not(.active):hover {
    background-color: #ddd;
}
