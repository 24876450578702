.table-legend {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; /* Reduced margin for a more compact legend */
    font-size: 12px; /* Slightly smaller font size */
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px; /* Reduced margin between legend items */
}

.status-dot {
    height: 10px; /* Slightly smaller dot size */
    width: 10px;  /* Slightly smaller dot size */
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px; /* Slightly reduced space between dot and text */
}

.status-dot.valid {
    background-color: var(--green);
}

.status-dot.expiring-soon {
    background-color: var(--yellow);
}

.status-dot.expiring-very-soon {
    background-color: orange;
}

.status-dot.expired {
    background-color: var(--red);
}
