.driver-legend {
    position: absolute;
    bottom: -12px;
    z-index: 9;
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 62px;
    font-size: 12px;
    margin-top: 0;
    border-top-right-radius: 12px;
    padding: 6px 12px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.status-dot {
    height: 10px; /* Dot size */
    width: 10px;  /* Dot size */
    border-radius: 50%; /* Circular dots */
    display: inline-block;
    margin-right: 6px;
}

.status-dot.online, .status-dot.green {
    background-color: var(--green);
}

.status-dot.on-order, .status-dot.yellow {
    background-color: var(--yellow);
}

.status-dot.offline, .status-dot.red {
    background-color: var(--red);
}

.status-dot.purple {
    background-color: purple;
}

.status-dot.yellow-to-purple {
    background: linear-gradient(to right, yellow, purple);
}

.status-dot.red-to-green {
    background: linear-gradient(to right, red, green);
}

.status-dot.pin {
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 13px solid red;
    margin-left: 18px;
    margin-right: 20px;
    margin-bottom: -12px;
    transform: rotate(180deg);
}

.status-dot.pin::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: red;
}

.yellow-line {
    width: 45px;
    height: 5px;
    background-color: var(--yellow);
    margin-right: 6px;
}