.documents {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  column-gap: 25px;
  row-gap: 25px;
  margin-top: 20px;
}

.document-files {
  display: flex;
  flex-direction: row;
}

.document-file {
  margin-right: 4px;
}

.img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.document-expiration {
  margin-top: 10px;
  margin-bottom: 5px;
}