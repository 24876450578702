.numbering-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
}

.numbering-title {
    color: #333;
    font-size: 12px;
    margin-left: 2px;
}

.numbering-buttons-container {
    display: flex;
    align-items: center;
    width: 80px;
}

.numbering-buttons-container.preview {
    width: auto;
}

.numbering-number {
    margin: 0 6px;
    font-size: 16px;
}
