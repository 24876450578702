.header-default {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 48px;
    height: 91px;
    top: 0;
    width: 75%;
    left: 15px;
    right: 0;
    border-bottom: 1px #707070 solid;
    margin-bottom: 0;
}
.step {
    color: #6A6A6A;
    font-size: 20px;
    font-weight: 600;
}
.header-title {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    padding-left: 24px;
}
.sharepoint .header-title {
    color: #008941;
}
.sharepoint .header-default {
    border-bottom: none;
}

.header-welcome {
    justify-content: flex-end;
    opacity: 0.85;
    border-bottom: 1px #fff solid;
}
.header-welcome .p-welcome {
    color: #fff;
    font-size: 20px;
}

.header-create-menu, .header-interactive {
    justify-content: space-between;
}

.header-double-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header-double-action > div:first-child {
    margin-right: 18px;
}

.header-sharepoint-folder {
    justify-content: space-between;
}
.header-sharepoint-folder p.header-description {
    color: #707070;
    font-size: 14px;
}
textarea[disabled].input-sharepoint_folder_description {
    background-color: rgb(248, 248, 248);
    cursor: pointer;
}
.header-pwd {
    font-size: 14px;
    color: #707070;
    position: absolute;
    top: 70px;
    text-transform: uppercase;
}