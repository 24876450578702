/* Center the dropdown container */
.dropdown-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Style the dropdown */
select {
    margin-left: 5px;
    padding: 5px;/*10px 40px 10px 10px;*/
    font-size: small;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="none" stroke="black" stroke-width="2" d="M2 7l10 10L22 7"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
}

/* Style the dropdown options */
option {
    padding: 10px;
    font-size: inherit;
}