.driver-info-container {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.driver-info-title {
    color: #333;
    margin-bottom: 24px;
    text-align: center;
}

.driver-info-container p {
    font-size: 14px;
    margin: 8px 0;
}

.driver-info-container h4 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #555;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.driver-info-container strong {
    color: #555;
}

.driver-info-container span {
    font-weight: bold;
    text-align: center;
}

.driver-status {
    display: flex;
    align-items: center;
    margin-top: -6px;
    margin-bottom: -8px;
}

.driver-status.handle {
    justify-content: space-between;
    width: 262px;
}

.driver-active {
    display: flex;
    align-items: center;
    margin-top: -6px;
    margin-bottom: -8px;
}

.status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.status-dot.online {
    background-color: #5cb85c; /* Green */
}

.status-dot.offline {
    background-color: #d9534f; /* Red */
}

.status-on-order {
    color: #d9534f; /* Red */
}

.status-available {
    color: #5cb85c; /* Green */
}

.vehicle-info {
    margin-bottom: 15px;
}

.vehicle-info div {
    margin-bottom: 5px;
}

.driver-info-grid {
    display: flex;
    justify-content: space-between;
}

.driver-info-column {
    padding: 0 20px;
}

strong {
    color: #555;
}

/* Additional styles for User's BasicInfo */
.user-info-container {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.user-info-title {
    color: #333;
    margin-bottom: 24px;
    text-align: center;
}

.user-info-container p {
    font-size: 14px;
    margin: 8px 0;
}

.user-info-container h4 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #555;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.user-info-container strong {
    color: #555;
}

.user-info-container span {
    font-weight: bold;
}

.active.yes {
    color: var(--green);
    font-size: 16px;
}

.active.no {
    color: var(--red);
    font-size: 13px;
}

.handle.yes {
    color: var(--green);
    font-size: 16px;
}

.handle.no {
    color: var(--red);
    font-size: 13px;
}