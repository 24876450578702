.reviews-column .card {
    /*max-width: 560px;*/
}

.review {
    margin: 0 18px;
    padding: 18px;
    max-height: 430px;
}

.driver-review .review-content {
    top: -230px;
}

.customer-review .review-content {
    top: -155px;
}

.review-header h4 {
    max-width: 400px;
}

.reviewer {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    /*align-items: center;*/
    justify-content: space-between;
}

.reviewer img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.reviewer .reviewer-info {
    margin-left: 0px;
}

.reviewer .name {
    font-weight: bold;
}

.reviewer .date {
    color: #F96D08;
    font-weight: 500;
    font-size: 14px;
}

.reviewer .ratings {
    margin-left: auto;
    margin-top: -40px
}

.reviewer .ratings .rating {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin-bottom: 6px;
}

.reviewer .ratings .rating .rating-title p {
    font-size: 13px;
    margin: 0;
}

.reviewer .ratings .rating .rating-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.reviewer .ratings .rating .stars {
    display: flex;
    align-items: center;
}

.reviewer .ratings .rating .rate {
    font-weight: bold;
}

.reviewer .ratings .rating .stars img {
    width: 20px;
    height: 20px;
}

.review-content {
    font-size: 13px;
    max-width: 60%;
    position: relative;
    height: 0;
}

.rating-general > .rating-wrap {
    margin-bottom: 24px;
}

.customer-review .rating-general {
    margin-top: 24px;
}

.overall-experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.experience-icon {
    width: 30px !important;
    height: auto !important;
    margin-bottom: 5px;
    object-fit: contain;
    border-radius: 0 !important;
}

.experience-subtitle {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
}

.experience-subtitle.text-good {
    color: darkgreen;
}

.experience-subtitle.text-bad {
    color: darkred;
}

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.circle-container img {
    margin-bottom: 0px;
}

.circle-container.green {
    background-color: rgba(0, 255, 0, 0.3);
    border: 2px solid darkgreen;
}

.circle-container.red {
    background-color: rgba(255, 0, 0, 0.3);
    border: 2px solid darkred;
}

.experience-icon {
    width: 30px;
    height: 30px;
}