/* General container for the requirements section */
.requirements-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.scroll-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow-x: auto;
    padding: 8px 0;
}

.extras-container-preview {
    margin: 4px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 8px;
    background-color: #fff;
    cursor: pointer;
}

.extras-icon {
    width: 40px; /* Fixed size to fit within the container */
    height: 40px; /* Fixed size to fit within the container */
    object-fit: contain;
    margin-bottom: 5px;
    transition: transform 0.3s ease;
}

.card-text {
    font-size: 10px;
    color: #333;
    text-align: center;
}

.info-text {
    font-size: 14px;
    color: #666;
    text-align: center;
}

.extras-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    width: 85px;
    height: 90px;
    border-style: solid;
    border-color: rgba(151, 173, 182, 0.53);
    border-width: 0.3px;
    margin: 0 4px;
}

.extras-button.selected.yellow {
    border: 2px solid var(--yellow); /* Yellow border for selected */
}

.extras-button.selected.green {
    border: 2px solid var(--green);
}

.extras-button.selected .extras-icon,
.extras-container-preview.selected .extras-icon {
    transform: scale(1.1); /* Slightly enlarge the image when selected */
}

.extras-button.disabled {
    background-color: var(--grayLight);
    cursor: not-allowed;
    border-color: rgba(151, 173, 182, 0.53);
    opacity: 0.6;
}

.extras-button.disabled:hover {
    background-color: var(--grayLight);
    transform: none;
}

.arrow {
    position: absolute;
    justify-content: center;
    background-color: var(--white);
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    height: 85%;
    width: 28px;
    z-index: 1;
    opacity: 0.5;
    border-radius: 12px;
}

.left-arrow {
    left: -5px;
}

.right-arrow {
    right: -8px;
}

.arrow-left {
    transform: rotate(180deg);
}