.reviews-list {
    width: 75%;
    padding-bottom: 24px;
}
.reviews {
    width: 100%;
}
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
   
    column-gap: 25px;
  }
  
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    row-gap: 25px;
    flex: 1;
  }