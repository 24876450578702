.menu-info-container {
    min-width: 420px;
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    h2{
        margin-bottom: 0;
    }
    ul{
        margin-top: 0;
    }
}

strong {
    color: #555;
}

/* Additional styles for User's BasicInfo */
.user-info-container {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.user-info-title {
    color: #333;
    margin-bottom: 24px;
    text-align: center;
}

.user-info-container p {
    font-size: 14px;
    margin: 8px 0;
}

.user-info-container h4 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: #555;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.user-info-container strong {
    color: #555;
}

.user-info-container span {
    font-weight: bold;
}