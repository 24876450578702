.map-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.map-container > div {
    border-top-right-radius: 8px;
}

.compassButton {
    position: absolute;
    right: 16px;
    top: 16px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.buttonIcon {
    width: 24px;
    height: 24px;
}

.driver-marker-icon {
    width: 30px;
    height: 30px;
}

.status-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.status-dot.online {
    background-color: #5cb85c; /* Green */
}

.status-dot.offline {
    background-color: #d9534f; /* Red */
}

/* Custom styles for the InfoWindow close button */
.gm-style .gm-ui-hover-effect {
    position: absolute !important;
    top: 2px;
    right: 4px;
    width: 18px !important;
    height: 18px !important;
    padding: 0;
}

.gm-style .gm-ui-hover-effect span {
    width: 18px !important;
    height: 18px !important;
    margin: 1px !important;
}

.gm-style .gm-ui-hover-effect > div {
    font-size: 12px;
}

.date-picker-wrapper {
    width: 50%;
}

/* OVERRIDE GOOGLE MAP STYLES */
.gm-style-mtc > button {
    height: 30px !important;
    font-size: 16px !important;
}
.gm-control-active.gm-fullscreen-control {
    height: 30px !important;
    border-radius: 4px !important;
}
.gmnoprint, .gm-svpc {
    zoom: 0.9 !important;
}