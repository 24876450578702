.tabs {
    display: flex;
    flex-direction: column; /* Change to column to stack tabs vertically */
    gap: 5px; /* Adjust gap as needed */
}

.tab-button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px 5px; /* Make the tabs narrower */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s; /* Add transition for smooth scaling */
}

.tab-button.active {
    background-color: #d0d0d0;
    transform: scale(1.1); /* Enlarge the active tab */
}

.tab-button.collapsed {
    background-color: #e0e0e0; /* Different color for collapsed state */
}

.tab-content {
    margin-top: 10px;
}

.highlighted-zone {
    font-weight: 600;
    color: black !important; /* Override inline styles if necessary */
}
