.date-range-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.custom-date-range-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 360px;
}

/* Override the calendar grid to always have 7 columns */
.rdrCalendar .rdrDays {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 equal columns */
}

/* Adjust the size of the calendar container to ensure it fits */
.rdrCalendar {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

/* Adjust the day cell size */
.rdrDay {
    height: 27px;
    width: 27px;
    text-align: center;
    line-height: 25px;
}

/* Ensure days and date range selection are not impacted */
.rdrDayNumber {
    display: block;
    width: 100%;
}

.rdrDayNumber {
    outline: 0;
    font-weight: 300;
    left: 0;
    right: 0;
    position: relative;
    top: 0;
    bottom: 0;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrDayToday .rdrDayNumber span:after {
    background-color: #F05A28;
}

.date-range-picker {
    margin-right: -18px;
}