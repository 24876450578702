.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Align items in a column */
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Align items in a column */
}

.loading-spinner h3 {
    margin-bottom: 20px; /* Add some space between the text and the spinner */
    color: white; /* Set text color to white */
    font-size: 24px; /* Adjust font size */
    font-weight: bold; /* Make the text bold */
}