.radio-buttons-container {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.radio-buttons-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
}

.radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.row .radio-buttons {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.radio-button-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.radio-button-item input[type="checkbox"] {
    margin-right: 8px;
    accent-color: var(--yellow)
}

.radio-button-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    object-fit: contain;
}

.radio-button-text {
    font-size: 12px;
    color: #3E4958;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
}

.custom-checkbox {
    width: 14px;
    height: 14px;
    accent-color: var(--yellow)
}
