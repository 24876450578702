.category-title{
    margin: 0px;
    border-bottom:1px solid #ddd;
}
ul {
    list-style-type:none;
    padding-left: 15px;
    margin-bottom: 5px;
}

li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

div.item-image>img {
    max-width:40px;
    max-height:40px;
}
div.item-image{
    width:40px;
    height:40px;
    display: flex; /* Make the div a flex container */
    justify-content: center; /* Center horizontally */
    align-items: flex-end;
}
.item-container{
    height:40px;
    p,h4,div{
        margin: 0px 5px 0px 0px;
    }
    .item-price{
        margin-left: auto;
    }
    div>img{
        border-radius: 5px;
    }
}


.item-spicy>img{
    width:15px
}