/* DatePicker.css */

/* Container for the date picker input */
.date-picker-container {
    position: relative;
    font-family: Arial, sans-serif;
    margin-bottom: 8px;
}

/* Label for the date picker */
.date-picker-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

/* Container for the date picker input */
.date-picker-input-container {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
}

/* Disabled state for the date picker */
.date-picker-input-container.disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

/* Input field */
.date-picker-input {
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    color: #333;
}

/* Modal for DatePicker */
.date-picker-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup container for DatePicker */
.date-picker-popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header for the date picker modal */
.date-picker-header {
    background-color: #f5c542;
    color: #333;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    margin-bottom: 10px;
}

/* Custom styling for react-datepicker calendar */
.react-datepicker {
    font-family: Arial, sans-serif;
}

/* Increase the size of the calendar */
.react-datepicker__month {
    font-size: 1rem; /* Larger font size for month */
}

.react-datepicker__week {
    font-size: 0.5rem; /* Larger font size for week days */
}

.react-datepicker__day,
.react-datepicker__day-name {
    font-size: 1.2rem; /* Larger font size for days */
    height: 3rem; /* Taller day cells */
}

/* Adjust margins and paddings */
.react-datepicker__header {
    padding: 10px; /* Increase header padding */
    font-size: 4rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #f5c542; /* Highlight color for selected date */
    color: #fff;
}

/* Hover effect for date cells */
.react-datepicker__day:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
}

/* Custom calendar colors */
.calendar.yellow .react-datepicker__day--selected,
.calendar.yellow .react-datepicker__day--keyboard-selected {
    background-color: #f5c542;
    color: #fff;
}

.calendar.red .react-datepicker__day--selected,
.calendar.red .react-datepicker__day--keyboard-selected {
    background-color: #e94f4f;
    color: #fff;
}

.calendar.green .react-datepicker__day--selected,
.calendar.green .react-datepicker__day--keyboard-selected {
    background-color: #4caf50;
    color: #fff;
}
