.tabs-container {
    display: flex;
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-top: -2px;
    margin-bottom: 8px;
}

.tab-item {
    padding: 6px 12px;  /* Reduced padding for smaller tabs */
    cursor: pointer;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
    margin-right: 1px; /* Minimal horizontal spacing */
    transition: background-color 0.3s, color 0.3s;
    font-size: 14px;  /* Slightly reduced font size for a smaller appearance */
}

.tab-item:last-child {
    margin-right: 0;
}

.tab-selected {
    background-color: #F05A28;
    color: #fff;
    border-color: #F05A28;
    position: relative;
}

.tab-selected::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #F05A28;
}
