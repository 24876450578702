.time-picker-container {
    position: relative;
    font-family: Arial, sans-serif;
    z-index: 99;
}

.time-picker-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.time-picker-input-container {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    background: #fff;
    position: relative;
}

.time-picker-input-container.disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.time-picker-input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
}

.time-picker-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.time-picker-modal {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 300px;
}

.time-picker-modal-close {
    position: absolute;
    background: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    margin: 10px
}

.react-time-picker {
    width: 100%;
    height: 50px;
}

.react-time-picker button {
    display: none; /* Hide all buttons within the TimePicker component */
}

