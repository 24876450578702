.register-container {
    box-shadow: 2px 2px 20px -10px;
    max-width: 1500px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 110px;
    background: #fff;
    border-radius: 25px;
}
.logo {
    padding-top: 30px;
    padding-bottom: 20px;
}

.logo img {
    border-radius: 12px;
}

.register-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-column {
    padding: 0 12px;
    width: 320px;
}

.checkbox-container {
    margin-top: 22px;
}
.checkbox-container input {
    margin: 0 12px;
    height: 15px;
    width: 15px;
}
.checkbox-container input:checked {
    background: #008941;
}
.checkbox-container label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
}
