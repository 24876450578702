.list-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.data-squares-row {
    display: flex;
    gap: 10px;
    margin: 12px 16px;
    align-items: center;
}

.data-square {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 12px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    min-height: 72px;
    margin-right: 12px;
}

.data-value {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.data-label {
    font-size: 12px;
    color: #777;
    margin-top: 6px;
}
