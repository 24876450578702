.contact-form {
    margin: 0 auto;
    padding: 10px;
    max-width: 1200px;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
}

.button-container-form {
    display: flex;
    justify-content: center;
    width: 92%;
}

h4 {
    margin-bottom: 10px;
}

.double-input {
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.input {
    width: 100%;
}


