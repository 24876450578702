/* Modal Overlay */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
}

/* Modal Container */
.custom-modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    min-width: 300px;
    max-width: 900px; /* Adjust the maximum width as needed */
    max-height: 85%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center text inside the modal */
    position: relative;
    overflow-y: auto;
}

/* Modal Title and Description */
.modal-title {
    font-size: 20px;
    margin-bottom: 4px;
}

.modal-description {
    margin-bottom: 20px;
    font-size: 14px;
}

.modal-button-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.single-button-center {
    justify-content: center; /* Center a single button */
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    width: 46%;
}

/* Button Colors */
.button-green {
    background-color: var(--green);
    color: white;
}

.button-red {
    background-color: var(--red);
    color: white;
}

.button-yellow {
    background-color: var(--yellow);
    color: black; /* Default text color for yellow button */
}

/* Hover Effect */
.modal-button:hover {
    color: black;
}

/* Modal Close Button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    color: black;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s, color 0.3s;
}

.modal-close:hover {
    background: var(--red);
    color: white;
}

.left-section {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
}

.left-section.child {
    margin-right: 10px;
    display: block;
}

.right-section {
    padding: 0 10px;
}

.right-section.child {
    margin-left: 0;
    display: block;
}