.input-container {
    margin: 16px 0;
}

input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

form input {
    height: 36px;
    background: #F0F0F0;
    border: none;
    outline: none;
    padding-left: 12px;
}

form label ::placeholder {
    font-weight: 400;
    color: #6e6e6e !important;
    font-size: 13px !important;
}

.label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}

form textarea:focus {
    outline: none;
}

textarea {
    resize: vertical;
    height: auto;
}

form input:disabled {
    opacity: 0.5;
}

input:disabled {
    background-color: #f0f0f0;
}


.form-container .p-info {
    text-align: center;
    font-size: 9pt;
    color: #707070;
    font-style: italic;
}

.form-container {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.input-default {
    border-radius: 5px;
    font-size: 14px;
    color: #707070;
    width: 100%;
}

.input-shadow {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5pt solid #707070;
}

.input-white {
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;;
    color: #707070;
}

.input-textarea {
    min-height: 40px;
    max-height: 200px;
    width: 94%;
    padding: 12px 12px;
}

.input-price {
    width: 228px;
    height: 48px;
    margin-right: 16px;
    margin-top: 9px;
    border-radius: 5px;
    font-size: 14px;
}

form input.input-login {
    margin-bottom: 36px;
    max-width: 96%;
}

form input.input-default {
    margin-bottom: 5px;
    width: 300px;
}

.input-sharepoint_folder_description {
    height: 40px;
    margin: 0 !important;
}

.general-picker-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
}

.search-container {
    display: flex;
    width: 100%;
    align-items: center;
}

.picker {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
}

.predictions-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}

.prediction-container {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s;
}

.prediction-container:hover {
    background-color: #f0f0f0;
}

.prediction {
    text-align: left;
    font-family: var(--font-inter-regular);
}

.input-search-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}

.input-search {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    align-items: flex-end;
    padding-left: 34px;
}

.search-icon {
    position: relative;
    right: -28px;
    top: 18px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.hide-icon {
    position: absolute;
    right: -8px;
    cursor: pointer;
    padding: 0 2px;
    margin-top: 2px;
    background: transparent;
    border: none;
    box-shadow: none;
}

.small-upload {
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}