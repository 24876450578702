
@font-face {
  font-family: 'Inter-Black';
  src: url('./assets/fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
}

:root {
  --background: #F0F0F0;
  --primary: #b4aa00;
  --secondary: #000E52;
  --yellow: #FCCB1F;
  --yellowDark: #f5c213;
  --green: #7BAE37;
  --redDark: rgba(211, 41, 1, 0.69);
  --red: #d32901;
  --title: #09051C;
  --secondaryFaded: #f6ba9c;
  --tertiary: #b0b0b0;
  --quaternary: #949494;
  --quinary: #bebebe;
  --senary: #efefef;
  --gray: #B4B4B4;
  --grayLight: #dcdcdc;
  --almostWhite: #d9d9d9;
  --white: #fff;
  --backgroundWhite: #F5F5F5;
  --semiBlack: #333;
  --black: #000;
  --disabled: #dbdbdb;
  --text: #3E4958;
  --info: #97ADB6;
  --inputBorder: #A5A5A5;
  --transparent: transparent;

  --font-inter-black: 'Inter-Black', sans-serif;
  --font-inter-bold: 'Inter-Bold', sans-serif;
  --font-inter-extra-bold: 'Inter-ExtraBold', sans-serif;
  --font-inter-extra-light: 'Inter-ExtraLight', sans-serif;
  --font-inter-light: 'Inter-Light', sans-serif;
  --font-inter-medium: 'Inter-Medium', sans-serif;
  --font-inter-thin: 'Inter-Thin', sans-serif;
  --font-inter-regular: 'Inter-Regular', sans-serif;
  --font-inter-semi-bold: 'Inter-SemiBold', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
