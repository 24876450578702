.search-zone-bar-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-input-container {
    position: relative;
    width: 300px; /* Set a fixed width for the container */
}

.autosuggest__input {
    width: 100%; /* Make the input take up the full width of the container */
    padding: 5px 5px 5px 35px !important; /* Add padding on the left to make space for the icon */
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Ensure padding is included in the width */
}
.autosuggest__input:focus-visible {
   outline: none;
}
.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%); /* Center the icon vertically */
    pointer-events: none; /* Make sure the icon doesn't block input interactions */
    color: #888; /* Adjust the color as needed */
    z-index: 1; /* Ensure the icon is above the input */
}

.autosuggest__suggestions-container {
    position: absolute;
    z-index: 9999;
    width: 100%; /* Match the width of the input field */
    max-height: 200px; /* Adjust the height as needed */
    overflow-y: auto;
    background-color: white;
    margin-top: 5px; /* Add some space between the input and suggestions */
    padding-left: 10px;
}

.autosuggest__suggestion {
    padding: 10px;
    cursor: pointer;
    font-size: small;
}

.autosuggest__suggestion--highlighted {
    background-color: lightgray;
}